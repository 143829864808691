<template>
  <div>
    <v-form ref="voting_form">
      <v-row class="mb-4">
        <v-col>
          <h2 v-if="isAddVoting">Add Voting</h2>
          <h2 v-else>Edit Voting</h2>
        </v-col>
      </v-row>
      <v-card elevation="0" class="px-6 py-4 mb-8">
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" col="12" class="xs-full">
            <v-menu
              ref="start_date_menu"
              v-model="start_date_menu"
              :close-on-content-click="false"
              :return-value.sync="start_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="start_date"
                  label="Start Date"
                  prepend-icon=""
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  type="text"
                  :rules="[Validator.required]"
                  hide-details="auto"
                ></v-text-field>
              </template>
              <v-date-picker v-model="start_date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="start_date_menu = false"> 取消 </v-btn>
                <v-btn text color="primary" @click="$refs.start_date_menu.save(start_date)"> 確定 </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" col="12" class="xs-full">
            <v-menu
              ref="start_time_menu"
              v-model="start_time_menu"
              :close-on-content-click="false"
              :return-value.sync="start_time"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="start_time"
                  label="Start"
                  prepend-icon=""
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  type="text"
                  :rules="[Validator.required]"
                  hide-details="auto"
                >
                </v-text-field>
              </template>
              <v-card class="py-2 px-2">
                <div>
                  <v-select
                    label="時"
                    :items="hour_list"
                    hide-details="auto"
                    v-model="hour"
                    :error="time_error"
                  ></v-select>
                  <v-select
                    label="分"
                    :items="min_list"
                    hide-details="auto"
                    v-model="min"
                    :error="time_error"
                  ></v-select>
                </div>
                <v-spacer class="mt-2"></v-spacer>
                <v-btn text color="primary" @click="start_time_menu = false"> 取消 </v-btn>
                <v-btn text color="primary" @click="saveTime"> 確定 </v-btn>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>

        <v-row class="mt-10">
          <v-col xl="10" lg="10" md="10" sm="10" col="12">                
              <v-img
                    width="150"
                    height="150"
                    :src="voting_image"
                >
                </v-img>
              <v-file-input
                  id="product_image"
                  v-show="false"
                  accept="image/*"
                  @change="getEventImageFile($event)"
              ></v-file-input>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col>
              <v-btn color="primary" @click="triggerUploadImage('product_image')" :loading="submitLoading"
              >UPLOAD</v-btn>
          </v-col>
        </v-row>
        
        <v-row class="mt-15">
          <v-col class="text-right">
                <v-btn color="primary" @click="callQuestionDialog('add_question')" :loading="submitLoading"
                >Add Question</v-btn>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col>
            <data-table-with-pagination
                :headers="var_header"
                :dataList="var_list"
                :loading="load_question_table"
                v-on:closeCustomOption="callQuestionDialog"
              ></data-table-with-pagination>
          </v-col>
        </v-row>
        

        <v-row>
          <v-col>
            <v-btn color="success" :loading="submitLoading" @click="saveVotingData()">儲存</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-form>

    <Dialog
      :dialog="dialog.open"
      :text="dialog.text"
      :isDeleteDialog="dialog.isDelete"
      v-on:close="dialogClose"
      :max_width="dialog.max_width"
      :loading="submitLoading"
    >
      <div class="mt-4" v-if="dialog.type=='edit' || dialog.type=='add_question'">
        <v-row>
          <v-col>
            <v-text-field
              type="text"
              label="Question"
              outlined
              v-model="questions.question"
              hide-details="auto"
              :error="error.question_title"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
         <v-col>
            <v-btn @click="addAnswer" class="primary">ADD ANSWER</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="d-flex div-row" v-for="(ans , index) in answers" :key="index">
              <v-text-field
                v-model="answers[index]"
                type="text"
                :label="'Answer' + (index + 1 )"
                outlined
                hide-details="auto"
                :rules="[Validator.required]"
                :error="error_answer[index]"
              >
              </v-text-field>
              <v-btn @click="removeAnswer(index)" color="error" text height="100%" class="question-remove">刪除</v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </Dialog>
    <LoadingDialog :load="showLoadingPage"></LoadingDialog>
    <AlertBox :type="alertBox.type" :text="alertBox.text" :show="alertBox.show"></AlertBox>
  </div>
</template>

<script>
import { Validator } from '@/global'
import LoadingDialog from '@/components/LoadingDialog'
import AlertBox from '@/components/AlertBox'
import Dialog from '@/components/Dialog'
import DataTableWithPagination from '@/components/DataTableWithPagination.vue'
import { globalFunc, MEDIA_PATH } from '@/global'
import { mapActions } from 'vuex'
import { getData, postData } from '@/api'
import { mdiDeleteForever } from '@mdi/js';


export default {
  name: 'voting-management-detail',
  components: {
    Dialog,
    LoadingDialog,
    AlertBox,
    DataTableWithPagination,
  },
  data: () => ({
    user_center_id: -1,
    verifiedError: false,
    isAddVoting: true,
    start_date: null,
    voting_image: require('@/assets/images/images/no_image.png'),
    backup_start_date: null,
    globalFunc: globalFunc,
    showLoadingPage: false,
    submitLoading: false,
    start_date_menu: false,
    end_date_menu: false,
    voting_variable_num: 1,
    question_index: -1,
    icons: {
      mdiDeleteForever
    },
    tab: null,
    alertBox: {
      show: false,
      text: '',
      type: '',
    },
    dialog: {
      isDelete: true,
      open: false,
      text: '刪除影片',
      index: -1,
      type: '',
      max_width: 600,
    },
    ans:null,
    var_header: [
      { text: 'Question', value: 'question' },
      { text: '', value: 'custom_actions_field' , width: '220'},
    ],
    error: {
      question_title: false,
    },
    error_answer: [],
    var_list: [],
    backup_var_list: [],
    photos: [
        { id: 1, src: 'no_image.png' },
    ],
    questions: [],
    backup_questions: [],
    answers: [],
    backup_answers: [],
    record_data: [],
    load_record_table: true,
    register_detail_data: [],
    Validator: Validator,
    voting_data: {
      username: '',
      user_type: '',
      old_password: '',
      password: '',
      permission_type: '',
      position: '',
      center_id: '',
      status: '',
    },
    backup_voting_data: {},
    dialog: {
      isDelete: true,
      open: false,
      text: '刪除影片',
      index: -1,
      type: '',
      max_width: 600,
    },
    load_question_table:false,
    start_time: null,
    backup_start_time: null,
    start_time_menu: null,
    hour_list: ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"],
    min_list: ["00","15","30","45"],
    time_error: null,
    hour: null,
    min: null,
  }),
  methods: {
    ...mapActions(['reset']),
    /**
     * Delete an question
    */
    deleteVotingVariable(id) {
      this.var_list.splice(id, 1);
    },
    /**
     * Delete an answer from dialog box
    */
    removeAnswer(index) {
      this.answers.splice(index,1);
    },
    /**
     * triggering an image input file by an element selector
     * @param {string} id - Selector of the input field
    */
    triggerUploadImage(id) {
      document.getElementById(id).click()
    },
    /**
     * Save the time to display input after click the confirm from menu dialog
    */
    saveTime() {
      this.start_time = `${this.hour}:${this.min}`
      this.$refs.start_time_menu.save(this.start_time)
    },
    /**
     * Initializing the data
    */
    async initializeData() {
      try {
        let params = new URLSearchParams()
        params.set(
          'get_vote_by_id',
          JSON.stringify({
            id: this.$route.params.id,
          }),
        )
        let result = await getData(params)
        console.log('--- Get voting data ---')
        console.log(result)
        if (result) {
          let data = result

          
          this.start_date= data.date
          this.backup_start_date= data.date
          this.start_time= data.time
          this.backup_start_time= data.time

          if(data.image) {
            this.voting_image = data.image
          } else {
            this.voting_image = require('@/assets/images/images/no_image.png')
          }

          for(let i=0; i < data.questions.length; i++) {
            this.var_list.push({ question: data.questions[i].question, answers: [] })
            this.backup_var_list.push({ question: data.questions[i].question, answers: [] })
            for(let j=0;j<data.questions[i].answers.length;j++) {
              this.var_list[i].answers.push(data.questions[i].answers[j])
              this.backup_var_list[i].answers.push(data.questions[i].answers[j])
            }
          }
        }
      } catch (error) {
        console.log('--- Get voting data fail ---')
        console.log(error)
      }
    },
    /**
     * Displaying the question dialog box
     * @param {string} type - The type of the dialog
     * @param {number} index - The index of the row of table
    */
    callQuestionDialog(type, index = -1) {
      this.dialog.open = true
      this.dialog.type = type
      this.dialog.max_width = 600
      this.dialog.isDelete = true
      if (type == 'add_question') {
        this.dialog.isDelete = false
        this.dialog.text = '新增問題'
        this.dialog.max_width = 1000

        this.questions=[]
        this.answers = [""]
        this.error_answer[0] = false;

      } else if (type == 'edit') {
        this.dialog.isDelete = false
        this.dialog.text = '更改問題'
        this.dialog.max_width = 1000
        this.dialog.index = index
        
        let data = {
          question: index.question,
        }
        
        let ans_data = []
        this.answers = []

        this.backup_questions = {
          question: index.question,
        }


        for(let i = 0; i<index.answers.length; i++ ) {
          ans_data.push( index.answers[i] );
          this.backup_answers.push( index.answers[i] )
        }
        Object.assign(this.questions, data)
        Object.assign(this.answers, ans_data)

        this.question_index = index;

      } else {
        this.dialog.isDelete = true
        this.dialog.text = '刪除問題'
        this.dialog.index = index
      }
    },
    /**
     * upload product image
     * @param {file} - input from file input
    */
    async getEventImageFile(file) {
      if (globalFunc.DataValid(file)) {
        const vm = this
        if (file) {
          try {
            let bas4Path = await globalFunc.firstFileToBase64(file)

            let type = file.type.substring(file.type.lastIndexOf('/') + 1)
            let params = new URLSearchParams()
            let send_data = {
              upload_data: bas4Path,
              upload_file_type: type,
            }
            
            params.set('upload_file', JSON.stringify(send_data))

            const config = {
              onUploadProgress: progressEvent => {
                console.log(progressEvent.loaded)
                vm.progress = progressEvent.loaded * 0.75
              },
            }

            let upload_image = await postData(params, config)
            this.voting_image = MEDIA_PATH + upload_image;
            console.log(this.voting_image)

            vm.uploading = false
            vm.progress = 100
          } catch (error) {
            console.log(error)
            vm.uploading = false
          }
        }
      }
      
    },
    /**
     * Close dialog and pass the data the table , if cancel then do nothing (action = null)
    */
    dialogClose(action) {
      
      if (action) {        
        if (this.dialog.type == 'add_question' || this.dialog.type == 'edit') {
          if (!this.questionValidate()) {
            return
          } else {
            let data = { question: this.questions.question , answers:[] }
            for(let i =0; i<this.answers.length; i++ ) {
              data.answers.push(this.answers[i])
            }

            if (this.dialog.type == 'add_question') {
              this.var_list.push(data)
              this.backup_var_list.push(data)
            } else if (this.dialog.type == 'edit') {
              Object.assign(this.question_index, data)
            }
            
          }
        } else {
          this.var_list.splice(this.dialog.index, 1)
        }
      }
      this.dialog.open = false

    },
    /**
     * Validator of the dialog
    */
    questionValidate() {
      let validate = true
      if (globalFunc.DataValid(this.questions.question)) {
        this.error.question_title = false
      } else {
        this.error.question_title = true
        validate = false
      }

      for(let i=0;i<this.answers.length;i++) {
        if(globalFunc.DataValid(this.answers[i])) {
          this.error_answer[i]=false
        } else {
          this.error_answer[i]=true
          validate = false
        }
      }
      return validate
    },
    /**
     * Adding an answer to the question
    */
    addAnswer() {
      this.answers.push("");
      this.error_answer[this.answers.length+1] = false;
    },
    /**
     * Saving the voting data to backend
    */
    async saveVotingData() {
      if (!this.$refs.voting_form.validate()) {
        return
      }

      try {
        let key = ''
        let send_data = {}

        if (!this.isAddVoting) {
          send_data.id = parseInt(this.$route.params.id)

          let updated = false
          if (this.start_date != this.backup_start_date) {
            updated = true
            send_data.date = this.start_date
          }

          if (this.start_time != this.backup_start_time) {
            updated = true
            send_data.time = this.start_time
          }

          if (this.var_list.length != this.backup_var_list.length) {
            updated = true
            send_data.questions = this.var_list
          }

          if(this.var_list.image != this.voting_image) {
            updated = true
            send_data.image = this.voting_image
          }

          for(let i=0;i<this.var_list.length && this.backup_var_list.length ;i++) {
            if(this.var_list[i].question != this.backup_var_list[i].question ) {
              updated = true
              send_data.questions = this.var_list
            }

            if(this.var_list[i].answers.length != this.backup_var_list[i].answers.length) {
              updated = true
              send_data.questions = this.var_list
            }

            for(let j=0;j<this.var_list[i].answers.length && j<this.backup_var_list[i].answers.length;j++) {
              if(this.var_list[i].answers[j] != this.backup_var_list[i].answers[j] ) {
                updated = true
                send_data.questions = this.var_list
              }
            }
          }

          if (!updated) {
            this.alertBox.text = '沒有資料需要更新'
            this.alertBox.type = 'error'
            this.alertBox.show = true
            return
          }

          key = 'update_vote'

        } else {
          send_data.date = this.start_date
          send_data.time = this.start_time
          send_data.questions = this.var_list
          send_data.image = this.voting_image
          key = 'new_vote'

          if (!this.var_list.length) {
            this.alertBox.text = '至少加入一個問題'
            this.alertBox.type = 'error'
            this.alertBox.show = true
            return
          }

          console.log(send_data)
        }

        let params = new URLSearchParams()
        params.set(key, JSON.stringify(send_data))
        let result = await getData(params)
        console.log(result)

        if (!Array.isArray(result.data)) {
          this.alertBox.text = '儲存成功'
          this.alertBox.type = 'success'
          this.alertBox.show = true
          setTimeout(() => {
            this.$router.replace({ name: 'voting-management' })
          }, 1500)
        } else {
          this.alertBox.text = '沒有資料需要更新'
          this.alertBox.type = 'error'
          this.alertBox.show = true
        }
      } catch (error) {
        console.log(error)
        this.alertBox.text = '儲存失敗'
        this.alertBox.type = 'error'
        this.alertBox.show = true
      } finally {
        setTimeout(() => {
          this.submitLoading = false
          this.alertBox.show = false
        }, 1500)
      }
    },
    /**
     * Kicking out of the user
    */
    kickout() {
      console.log('--- Validation Fail ---')
      this.alertBox.text = '登入憑證過期，3秒後重新導向'
      this.alertBox.type = 'error'
      this.alertBox.show = true

      setTimeout(() => {
        this.alertBox.show = false
        let send_data = []
        send_data['method'] = 'resetData'
        this.reset(send_data)
        this.$router.push({ name: 'Login' })
      }, 3000)
    },
  },
  /**
   * Initializing at the beginning
   * @constructor
  */
  created() {
    setTimeout(async () => {
      console.log('--- Validation ---')
      if (globalFunc.DataValid(this.$store.state.api.login_data)) {
        try {
          this.isAddVoting = true
          this.showLoadingPage = true
          setTimeout(() => {
            this.showLoadingPage = false
          }, 1000)

          if (globalFunc.DataValid(this.$route.params.id)) {
            this.isAddVoting = false
            this.initializeData()
          }
        } catch (error) {
          console.log('--- Check User Permission Fail ---')
          console.log(error)
          this.verifiedError = true
          this.showLoadingPage = false
          this.alertBox.text = '資料取得失敗'
          this.alertBox.type = 'error'
          this.alertBox.show = true

          // setTimeout(() => {
          //   this.alertBox.show = false
          //   this.$router.replace({ name: 'voting-management' })
          // }, 3000)
        }
      } else {
        this.kickout()
      }
    }, 200)
  },
  watch: {
    /*     'voting_data.user_type': function (newVal) {
      if (this.isAddVoting) {
        if (this.center_readOnly) {
          this.center_readOnly = false
          this.center_rules = [Validator.required]
        }
      }
    }, */
    
  },
}
</script>


<style lang="scss" scoped>
  .div-row{
    align-items: center;
    justify-content: space-between;
  }
  .question-remove{
    margin-top:-10px;
  }
</style>